import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogEvent from "../../log/LogEvent";

const StyledFAIcon = styled(FontAwesomeIcon)`
  color: ${({ color, theme: { colors } }) => colors[color] || colors.darkestShade};
  font-size: ${({ fontSize }) => fontSize || "20px"};
  &:hover {
    opacity: ${({ hover: { opacity } = {} }) => opacity || "1"};
  }
`;

// import dynamically
export default ({ icon, logEventProps, ...props }) => (
  <LogEvent logEventProps={logEventProps} actionProps={{ onClick: { action: "click" } }} elementType="icon">
    <StyledFAIcon icon={icon} {...props} />
  </LogEvent>
);
