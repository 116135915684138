import React, { useState, useRef, useEffect } from "react";
import { chain, includes } from "lodash";
import { useDropzone } from "react-dropzone";

import Box from "../Box/Box";
import Flex from "../Flex/Flex";
import LogEvent from "../../log/LogEvent";

import { Text } from "../Text/Text";
import { ASSETS } from "../../constants/assets";
import { SecondaryButton, SecondaryOutlinedButton } from "../Button/SecondaryButton";

const checkFileSize = (size) =>
  chain(size)
    .divide(1024) // KB
    .divide(1024) // MB
    .inRange(10) // 10MB
    .value();

const acceptedFileTypes = ["image/png", "image/jpeg", "application/pdf", "text/csv"];

const FileField = ({ children, onChange, fileRef, disabled = false, ...props }) => {
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadError, setUploadError] = useState("");

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      if (!checkFileSize(acceptedFiles[0].size)) {
        setUploadFile(null);
        setUploadError("Error: Maximum file size allowed is 10MB.");
      } else if (!includes(acceptedFileTypes, acceptedFiles[0].type)) {
        setUploadFile(null);
        setUploadError(`Error: File type ${acceptedFiles[0].type} is not supported.`);
      } else {
        setUploadFile(acceptedFiles[0]);
        setUploadError("");
        if (onChange) {
          onChange(acceptedFiles[0]);
        }
      }
    }
    if (acceptedFiles.length > 1) {
      setUploadFile(null);
      setUploadError("Error: Only one file is allowed.");
    }
  };

  const { open, getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled,
  });

  useEffect(() => {
    if (fileRef) {
      fileRef({ open });
    }
  }, []);

  const getUploadStatus = () => {
    if (uploadFile) {
      return (
        <Flex alignItems="center" justifyContent="center">
          <Text color="green">{`Selected: ${uploadFile.name}`}</Text>
        </Flex>
      );
    }

    if (uploadError) {
      return (
        <Flex alignItems="center" justifyContent="center">
          <Text color="danger">{uploadError}</Text>
        </Flex>
      );
    }

    return null;
  };

  return (
    <Flex alignItems="center" {...props} bg={disabled ? "#EFEFEF4D" : "transparent"}>
      <Box {...getRootProps({ className: "dropzone" })}>
        <Flex alignItems="center" justifyContent="center">
          <input {...getInputProps()} />
        </Flex>
        {children}
      </Box>
      {getUploadStatus()}
    </Flex>
  );
};

const FileInputField = ({ logEventProps, ...props }) => {
  const fileRef = useRef();

  const onSetFileRef = (ref) => {
    fileRef.current = ref;
  };

  return (
    <LogEvent logEventProps={logEventProps} actionProps={{ onClick: { action: "click" } }} elementType="file">
      <FileField p="6px" width="100%" cursor="pointer" border="solid" borderWidth={1} borderColor="mediumShade" borderRadius={8} fileRef={onSetFileRef} {...props}>
        <SecondaryButton mr={2} py="2px" borderWidth={1} borderColor="mediumShade" borderStyle="solid" onClick={onSetFileRef.current?.open}>
          Browse
        </SecondaryButton>
      </FileField>
    </LogEvent>
  );
};

const FileDropInputField = ({ logEventProps, ...props }) => {
  const fileRef = useRef();

  const onSetFileRef = (ref) => {
    fileRef.current = ref;
  };

  return (
    <LogEvent logEventProps={logEventProps} actionProps={{ onClick: { action: "click" } }} elementType="filedrop">
      <FileField p={8} width="600px" border="dashed" borderWidth={2} borderColor="#00000030" alignItems="center" justifyContent="center" flexDirection="column" fileRef={onSetFileRef} {...props}>
        <Flex alignItems="center" justifyContent="center" mb={3}>
          <Box as="img" pl={2} src={ASSETS.BOT_FILE_AVATAR} />
        </Flex>
        <Flex alignItems="center" justifyContent="center">
          <Text mr={1}>Drag & Drop here or</Text>
          <SecondaryOutlinedButton onClick={onSetFileRef.current?.open}>Browse Files</SecondaryOutlinedButton>
        </Flex>
        <Flex alignItems="center" justifyContent="center" mt={1} mb={2}>
          <Text fontSize={1} color="darkShade">
            Maximum size: 10MB.
          </Text>
        </Flex>
      </FileField>
    </LogEvent>
  );
};

export { FileInputField, FileDropInputField };
