import amplitude from "amplitude-js";
import flatten from "flat";
import { set, forEach } from "lodash";

export const initalizeAmplitude = (key) => {
  amplitude.getInstance().init(key);
};

export const setAmpUserProps = (userProps) => {
  amplitude.getInstance().setUserId(userProps.uid);
  amplitude.getInstance().setUserProperties(userProps);
};

export const sendLog = (props, eventName) => {
  const finalPayloadShape = {};
  const shape = {
    page: "page",
    eventName: "eventName",
    section: "section",
    action: "action",
    component: "component",
    elementType: "elementType",
    subSource: "subSource",
    term: "term",
    metaData: "metaData",
  };
  forEach(flatten(props), (propValue, propKey) => {
    if (propValue) {
      const tagPath = shape[propKey];
      if (tagPath !== undefined) {
        set(finalPayloadShape, tagPath, propValue);
      }
    }
  });
  amplitude.getInstance().logEvent(eventName, finalPayloadShape);
};
