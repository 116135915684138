import React, { useEffect, useState } from "react";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { ErrorMessage } from "@hookform/error-message";
import { useForm, Controller } from "react-hook-form";
import { chain, get, map, isEmpty, isEqual, find, mapValues } from "lodash";
import { parseISO, formatISO, parse } from "date-fns";
import Box from "../Box/Box";
import Flex from "../Flex/Flex";
import { Label } from "../Checkbox/Checkbox";
import { Radio } from "../Radio/Radio";
import { Text } from "../Text/Text";
import { Select } from "../Select/Select";
import CreatableSelect from "../Select/CreatableSelect";
import DateInputField from "../InputField/DateInputField";
import PhoneInputField from "../InputField/PhoneInputField";
import { FileInputField } from "../InputField/FileInputField";
import Input from "../InputField/Input";

const FormField = ({ children, field, labelProps, labelTextProps, ...props }) => (
  <Box mb={1} width="100%" {...props}>
    <Flex mt={2} mb={1} justifyContent="space-between" alignItems="center" {...labelProps}>
      <Text fontSize={1} fontWeight="bold" color="darkestShade" {...labelTextProps}>
        {field.label}
      </Text>
      {field.labelRight && (
        <Flex justifyContent="right" alignItems="center">
          <Text color="darkestShade" fontSize={1} {...labelTextProps}>
            {field.labelRight}
          </Text>
        </Flex>
      )}
    </Flex>
    {children}
    <Flex justifyContent="space-between" alignItems="center">
      <Text color="danger" fontSize={1}>
        {field.errorLabel}
      </Text>
      <Text color="darkestShade" fontSize={1} {...labelTextProps}>
        {field.hintLabel}
      </Text>
    </Flex>
  </Box>
);

const Form = ({ formRef, form, fieldPerRow = 1, defaultFormData = {}, formSpacing = true, titleProps, inputLabelProps, inputLabelTextProps }) => {
  const [multiSelectMenuOpen, setMultiSelectMenuOpen] = useState({});

  const { control, setError, getValues, formState } = useForm({
    mode: "onChange",
    defaultValues: defaultFormData,
  });

  const isValid = () => {
    // formstate unable to detect isValid when all the form field's required is false.
    if (
      formState.isValid ||
      chain(form.fields)
        .filter((field) => field.required)
        .isEmpty()
        .value()
    ) {
      return isEmpty(formState.errors);
    }

    // formstate unable to detect isValid when no changes in form.
    const formValue = getValues();
    return (
      chain(form.fields)
        .filter((field) => field.required)
        .filter((field) => isEmpty(get(formValue, field.id, "")))
        .isEmpty()
        .value() && isEmpty(formState.errors)
    );
  };

  const getFormData = () => {
    if (isValid()) {
      return getValues();
    }
    return null;
  };

  useEffect(() => {
    if (formRef) {
      formRef({ getFormData, formState, isValid });
    }
  }, [formState]);

  const handleMultiSelectMenu = (id, state) => {
    setMultiSelectMenuOpen({
      ...mapValues(multiSelectMenuOpen, () => false),
      [id]: state,
    });
  };

  const getComponent = ({ ref, type, required, ...field }) => {
    switch (type) {
      case "options":
        return (
          <FormField field={field} labelProps={inputLabelProps} labelTextProps={inputLabelTextProps}>
            <Box mt={2}>
              {map(field.options, (option) => (
                <Label key={option.value} mr={2} m={1}>
                  <Radio
                    checked={isEqual(option.value, field.value)}
                    color={get(field, "disabled", false) ? "darkShade" : "primary"}
                    onChange={() => {
                      if (!get(field, "disabled", false)) {
                        field.onChange(option.value);
                      }
                    }}
                  />
                  <Text fontSize={14}>{option.label}</Text>
                </Label>
              ))}
            </Box>
          </FormField>
        );

      case "input":
        return <Input key={field.id} label={field.label} disabled={get(field, "disabled", false)} inputLimit={field.limit} as={field.as} labelProps={inputLabelProps} labelTextProps={inputLabelTextProps} {...field} />;

      case "phone":
        return (
          <FormField field={field} labelProps={inputLabelProps} labelTextProps={inputLabelTextProps}>
            <PhoneInputField
              country="sg"
              inputStyle={{ width: "100%" }}
              value={field.value}
              onChange={(value) => {
                const formattedPhone = /^\+/.test(value) ? value : `+${value}`;

                if (isValidPhoneNumber(formattedPhone)) {
                  return field.onChange(parsePhoneNumber(formattedPhone).formatInternational().replaceAll(" ", ""));
                }
                return setError("phone", {
                  type: "manual",
                  message: "Invalid phone number.",
                });
              }}
              disabled={get(field, "disabled", false)}
            />
          </FormField>
        );

      case "datetime": {
        const dateObj = isEmpty(field.value) ? "" : parseISO(field.value);
        return (
          <FormField field={field} labelProps={inputLabelProps} labelTextProps={inputLabelTextProps}>
            <DateInputField key={field.id} defaultDate={dateObj} onChange={(date) => field.onChange(date.toISOString())} disabled={get(field, "disabled", false)} />
          </FormField>
        );
      }

      case "date": {
        const dateObj = isEmpty(field.value) ? "" : parseISO(field.value);
        return (
          <FormField field={field} labelProps={inputLabelProps} labelTextProps={inputLabelTextProps}>
            <DateInputField key={field.id} onlyDate defaultDate={dateObj} onChange={(date) => field.onChange(formatISO(date, { representation: "date" }))} disabled={get(field, "disabled", false)} />
          </FormField>
        );
      }

      case "time": {
        // parseISO in date-fns does not support iso string without date. Details: https://github.com/date-fns/date-fns/issues/2160
        const dateObj = isEmpty(field.value) ? "" : parse(field.value, "HH:mm:ssXXX", new Date());
        return (
          <FormField field={field} labelProps={inputLabelProps} labelTextProps={inputLabelTextProps}>
            <DateInputField key={field.id} onlyTime defaultDate={dateObj} onChange={(date) => field.onChange(formatISO(date, { representation: "time" }))} disabled={get(field, "disabled", false)} />
          </FormField>
        );
      }

      case "file": {
        return (
          <FormField field={field} labelProps={inputLabelProps} labelTextProps={inputLabelTextProps}>
            <FileInputField onChange={(file) => field.onChange(file)} disabled={get(field, "disabled", false)} />
          </FormField>
        );
      }

      case "select": {
        return (
          <FormField field={field} labelProps={inputLabelProps} labelTextProps={inputLabelTextProps}>
            <Select defaultValue={find(field.options, { value: field.value })} menuPlacement="auto" maxMenuHeight={180} onChange={(selected) => field.onChange(selected.value)} options={field.options} isDisabled={get(field, "disabled", false)} />
          </FormField>
        );
      }

      case "multiSelect": {
        return (
          <FormField field={field} labelProps={inputLabelProps} labelTextProps={inputLabelTextProps}>
            <CreatableSelect
              menuIsOpen={get(multiSelectMenuOpen, field.id, false)}
              onFocus={() => handleMultiSelectMenu(field.id, true)}
              onBlur={() => handleMultiSelectMenu(field.id, false)}
              value={map(field.value, (value) => find(field.options, { value }))}
              options={field.options}
              onChange={(selected) => {
                field.onChange(
                  chain(selected)
                    .filter((option) => !chain(field.options).find({ value: option.value }).isEmpty().value())
                    .map((select) => select.value)
                    .value()
                );
              }}
              isDisabled={get(field, "disabled", false)}
              maxMenuHeight={180}
              isValidNewOption={() => false}
              menuPlacement="auto"
            />
          </FormField>
        );
      }

      case "custom":
        return <>{field.component}</>;

      default:
        return null;
    }
  };

  const getWidth = () => {
    if (fieldPerRow <= 1) {
      return "100%";
    }

    return `${95 / fieldPerRow}%`;
  };

  return (
    <>
      <Box key={form.id} mt={formSpacing ? 2 : 0}>
        <Flex justifyContent="space-between">
          <Box width="100%" mt={formSpacing ? "5px" : 0}>
            {form.title && (
              <Text mb={1} fontWeight="bold" fontSize={3} {...titleProps}>
                {form.title}
              </Text>
            )}
            {form.description && (
              <Text mb={1} fontSize={2} color="darkestShade">
                {form.description}
              </Text>
            )}
            <Box mt={2}>
              {chain(form.fields)
                .chunk(fieldPerRow)
                .map((fields, rowIndex) => (
                  <Flex key={rowIndex} justifyContent="space-between">
                    {map(fields, (field, fieldIndex) => (
                      <Box width={getWidth()} mr={1} mt={1} mb={1} key={fieldIndex}>
                        <Controller name={field.id} control={control} rules={{ required: field.required }} render={({ field: controllerField }) => getComponent({ ...field, ...controllerField })} />
                        <ErrorMessage
                          errors={formState.errors}
                          name={field.id}
                          message={`${field.label} is required.`}
                          render={({ message }) => (
                            <Text color="danger" fontSize={12}>
                              {message}
                            </Text>
                          )}
                        />
                      </Box>
                    ))}
                  </Flex>
                ))
                .value()}
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Form;
