import React, { useState } from "react";

import styled from "styled-components";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { Text } from "../Text/Text";
import FAIcon from "../Icon/FAIcon";
import Flex from "../Flex/Flex";
import Box from "../Box/Box";
import Dropdown from "../Dropdown/Dropdown";

import LogEvent from "../../log/LogEvent";

const ActionMenuText = styled(Text)``;

const ActionMenuItemBox = ({ children, divider = false, ...props }) => {
  const [hover, setHover] = useState(false);
  const active = hover || props.active;

  return (
    <Flex bg={active ? "lightShade" : "transparent"} alignItems="center" cursor="pointer" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} borderTopWidth={divider ? "1px" : ""} borderTopStyle={divider ? "solid" : ""} borderTopColor={divider ? "mediumShade" : ""} {...props}>
      {children}
    </Flex>
  );
};

const ActionMenuBadge = ({ text, ...props }) => (
  <Flex minWidth={30} height={24} py={2} px={2} bg="lightestShade" borderRadius={8} alignItems="center" justifyContent="center" {...props}>
    <Text color="fullShade" fontSize={14}>
      {text}
    </Text>
  </Flex>
);

const ActionMenuItem = ({ children, logEventProps, ...props }) => (
  <Box data-testid={props.testid}>
    <LogEvent actionProps={{ onClick: { action: "click" } }} logEventProps={logEventProps} elementType="action Item">
      <ActionMenuItemBox px={2} py={1} minHeight={44} alignItems="center" {...props}>
        {children}
      </ActionMenuItemBox>
    </LogEvent>
  </Box>
);

const ActionMenu = ({ label, children, open, dropdownRef, logEventProps, minWidth, ...props }) => {
  const defaulLabel = (
    <Flex data-testid={props.testid} height={30} px={2} alignItems="center">
      <FAIcon icon={faEllipsisV} hover={{ opacity: 0.6 }} />
    </Flex>
  );
  return (
    <Dropdown label={label || defaulLabel} open={open} dropdownRef={dropdownRef} minWidth={minWidth || 200} {...props}>
      <Box bg="white" mx={5} minWidth={minWidth || 200} boxShadow="medium" borderRadius={8} borderWidth={1} borderStyle="solid" borderColor="mediumShade">
        {children}
      </Box>
    </Dropdown>
  );
};

export { ActionMenuText, ActionMenuBadge, ActionMenuItem, ActionMenu };
