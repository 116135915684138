import React, { useState } from "react";
import Box from "../../Box/Box";
import Paper from "../../Paper/Paper";
import Calendar from "./Calendar";
import DigitalTime from "../Time/Time";

const CalendarWithTime = ({ defaultDate, is24Hr = false, onChange, ...props }) => {
  const [date, setDate] = useState(defaultDate);

  const handleChange = (newDate) => {
    setDate(newDate);

    if (onChange) {
      onChange(newDate);
    }
  };

  return (
    <Paper elevation={1} width="320px" bg="white" {...props}>
      <Box borderBottomStyle="solid" borderWidth={1} borderColor="mediumShade">
        <Calendar defaultDate={date} onChange={handleChange} elevation={0} bg="white" />
      </Box>
      <DigitalTime defaultDate={date} is24Hr={is24Hr} onChange={handleChange} elevation={0} bg="white" />
    </Paper>
  );
};

export default CalendarWithTime;
