import { get, mapValues, pickBy, isObject } from "lodash";
import flatten, { unflatten } from "flat";
/* eslint no-useless-escape: 0 */
/*
  Parse Message JSON
  *  Pass an object and variables and interpolate all keys and values, including nested objects
  *  Used in messaging to allow backend to templates for message object values and schema.
  *  Both keys and values can be interpolated e.g: 
  * {'${someDynamicKey}': { some: {deep: { nested: ${dynamicValue} } } } }
*/

export const TEMPLATE_VAR_REGEX = /\$\{([A-Za-z0-9][A-Za-z0-9\[\]\_\-\(\) \.\s]*?)\}/g;
export const JSON_TEMPLATE_VAR_REGEX = /"\$\{\s*([^}\s]+)\s*\}"/g;

/*
  Parse Message String
  Utility method for interpolating variables within a string.Supports json / nested json object strings.
*/
export const parseTemplateString = (str, interpolations = {}, regex = TEMPLATE_VAR_REGEX) => {
  return `${str}`
    .replace(regex, (_, token) => {
      const defaultToken = `\${${token}}`;
      try {
        const val = interpolations[token] ? parseTemplateString(interpolations[token], interpolations, regex) : defaultToken;

        const stringifiedObj = JSON.stringify(val);
        return val && typeof val === "object" ? stringifiedObj : val;
      } catch (e) {
        return defaultToken;
      }
    })
    .replace(/""{/g, '"{')
    .replace(/}""/g, '}"');
};

export const parseMessageJSON = (message, extraVariables = {}, parseValues = true) => {
  const metadata = get(message, "metadata", {});
  const variables = { ...get(metadata, "variables", {}), ...extraVariables };

  const stringVariables = pickBy(variables, (v) => !isObject(v));

  // INFO: Sanitize newline characters
  const sanitizedStringVariables = mapValues(stringVariables, (str) => str.replace(/\n/g, "\\n"));
  const objVariables = mapValues(
    pickBy(variables, (v) => isObject(v)),
    (v) => JSON.stringify(v)
  );

  const flatMessage = flatten(message);

  const parseJSON = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  };
  try {
    let interpolatedMessage = parseTemplateString(JSON.stringify(flatMessage), sanitizedStringVariables);
    interpolatedMessage = mapValues(
      JSON.parse(
        parseTemplateString(
          interpolatedMessage,
          mapValues(objVariables, (v) => (isObject(v) ? JSON.stringify(v) : v)),
          JSON_TEMPLATE_VAR_REGEX
        )
      ),
      parseValues ? parseJSON : (s) => s
    );
    return unflatten(interpolatedMessage);
  } catch (e) {
    return message;
  }
};
