import colors from "../../theme/colors";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    minWidth: state.selectProps.minWidth,
    fontSize: 14,
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    cursor: "pointer",
    minHeight: 45,
  }),
  indicatorSeparator: () => ({ display: "none" }),
  clearIndicator: (provided) => ({ ...provided, marginRight: -12 }),
  option: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
  menuPortal: (base) => ({ ...base, fontSize: 14, zIndex: 9999 }),
};

const customTheme = (theme) => ({
  ...theme,
  borderRadius: 8,
  colors: {
    ...theme.colors,
    primary25: colors.lightestShade,
    primary: colors.primary,
    primary50: colors.primary,
  },
});

export { customStyles, customTheme };
