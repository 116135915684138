import React from "react";
import Box from "../Box/Box";
import Flex from "../Flex/Flex";
import { Body } from "../Text/Text";
import { useControlledState } from "../../hooks/useControlledState";
import LogEvent from "../../log/LogEvent";

const Tab = ({ children, tabIndex, activeTab, handleChange, logEventProps, ...props }) => {
  const isActive = tabIndex === activeTab;
  return (
    <LogEvent logEventProps={logEventProps} actionProps={{ onClick: { action: "click" } }} elementType="tab">
      <Body border="solid" borderWidth={0} borderBottomWidth={isActive ? 2 : 0} borderColor="primary" p={2} onClick={() => handleChange(tabIndex)} cursor="pointer" medium {...props}>
        {children}
      </Body>
    </LogEvent>
  );
};

const TabPanel = ({ tabIndex, activeTab, ...props }) => {
  return tabIndex === activeTab ? <Box py={3} {...props} /> : null;
};

const Tabs = ({ children, onChange, headerProps = {}, panelProps = {}, activeTabIndex, ...props }) => {
  let tabCount = 0;
  let tabPanelCount = 0;
  const tabSection = [];
  const panelSection = [];
  const [activeTab, setActiveTab] = useControlledState(activeTabIndex, 0, onChange);

  const handleChange = (index) => {
    setActiveTab(index);
    if (onChange) onChange(index);
  };

  /**
   * Warn users if people forget to use Tab component inside Tabs
   */
  if (React.Children.toArray(children).filter((child) => child.type.name === Tab.name).length < 2) {
    /* eslint no-console:0 */
    console.warn("Tab requires at least 2 Tabpanel components");
  }

  React.Children.map(children, (child) => {
    if (child.type.name === Tab.name) {
      tabSection.push(
        React.cloneElement(child, {
          tabIndex: tabCount,
          activeTab,
          key: tabCount,
          handleChange,
        })
      );
      tabCount += 1;
    } else if (child.type.name === TabPanel.name) {
      panelSection.push(
        React.cloneElement(child, {
          tabIndex: tabPanelCount,
          key: tabPanelCount,
          activeTab,
        })
      );
      tabPanelCount += 1;
    }
  });

  return (
    <Box {...props}>
      <Flex border="solid" borderColor="mediumShade" borderWidth={0} borderBottomWidth={1} {...headerProps}>
        {tabSection}
      </Flex>
      <Box {...panelProps}>{panelSection}</Box>
    </Box>
  );
};

export { Tabs, Tab, TabPanel };
