import { useState, useEffect } from "react";
import { useInfiniteQuery, useQuery, useMutation, useQueryClient } from "react-query";
import { get, mapValues, isFunction, pick, isEmpty, isNil, pickBy, isObject } from "lodash";
import useApi from "./useApi";

export const mapSortingToQueryParams = (data) => pick(data, ["orderField", "orderDesc"]);

const mapFiltersToQueryParams = (params, filterOptions = []) => {
  const selectedFilters = pickBy(params, (v, k) =>
    [filterOptions]
      .flat()
      .filter(Boolean)
      .find(({ id }) => id === k)
  );

  const mapped = Object.keys(selectedFilters).reduce((accu, filterId) => {
    const filterOption = filterOptions.find(({ id }) => id === filterId);
    let accuParam = accu;

    filterOption.data.forEach((filter) => {
      const filterVal = selectedFilters[filterId][filter.id];
      if (isNil(filterVal)) {
        return;
      }
      if (isFunction(filter.toParams)) {
        const queryParams = filter.toParams(filterVal, accuParam);
        accuParam = { ...accuParam, ...queryParams };
        return;
      }
      if (filterVal && isObject(filter.toParams)) {
        accuParam = { ...accuParam, ...filter.toParams };
      }
    });
    return accuParam;
  }, params);
  return mapped;
};

export default ({ gqlDocument, queryType = "query", baseQueryKey, paginate = false, filters: initialFilters = {}, mapParams, ...rootQueryOptions }) =>
  ({ filters: additionalFilters = {}, variables = {}, headers = {}, applyFilters = true, ...queryOptions } = {}) => {
    if (!gqlDocument || !baseQueryKey) {
      throw Error("Must provide a GraphQL document and base query key.");
    }

    const queryClient = useQueryClient();
    let queryFn = paginate ? useInfiniteQuery : useQuery;
    const isMutation = queryType === "mutation";
    if (isMutation) {
      queryFn = useMutation;
    }
    const filters = { ...initialFilters, ...additionalFilters };

    const queryKey = [baseQueryKey, variables];
    const { client, sessionQueryKey } = useApi();

    const defaultFilters = pickBy(filters, (v) => !isFunction(v?.data));

    const [resolvedFilters, setResolvedFilters] = useState(defaultFilters);
    const [requestParams, setRequestParams] = useState("");

    const onResolveFilters = (res) => {
      const mappedFilters = mapValues(pickBy(filters), ({ data, ...filter }) => ({
        ...filter,
        data: isFunction(data) ? data(res) : data,
      }));

      setResolvedFilters(mappedFilters);
    };

    const request = async ({ pageParam, queryKey: queryKeyParam, ...otherParams }) => {
      // eslint-disable-next-line no-unused-vars
      const [_, _requestParams] = isMutation ? ["", otherParams] : queryKeyParam;

      const reqVariables = {
        ...mapSortingToQueryParams(variables),
        ...(applyFilters ? mapFiltersToQueryParams(variables, Object.values({ ...resolvedFilters, ...additionalFilters })) : {}),
        ...pickBy(_requestParams, (param) => !isNil(param)),
        after: paginate ? pageParam : _requestParams.after,
      };

      if (paginate && paginate !== true) {
        delete reqVariables.after;
        reqVariables[paginate] = pageParam;
      }

      if (paginate === "offset") {
        delete reqVariables.after;
        reqVariables.offset = pageParam;
      }

      if (paginate && !("first" in reqVariables)) {
        reqVariables.first = 25;
      }

      const params = isEmpty(filters) ? reqVariables : mapFiltersToQueryParams(reqVariables);
      const mappedParams = isFunction(mapParams) ? mapParams(params) : params;
      setRequestParams(mappedParams);
      try {
        const res = await client.request(gqlDocument, mappedParams, headers, {
          queryKey: queryKeyParam,
        });
        return res;
      } catch (e) {
        const { response } = e;
        const shouldCheckSession = /^5\d{2}|^4\d{2}$/.test(response?.status);
        if (shouldCheckSession) {
          queryClient.refetchQueries(sessionQueryKey);
        }
        const res = get(e, "response.data", {});
        return res;
      }
    };

    const defaultQueryOptions = {
      ...rootQueryOptions,
      ...queryOptions,
    };
    const { onSuccess } = defaultQueryOptions;
    const onGlobalSuccess = (data) => {
      if (onSuccess) {
        onSuccess({ queryClient, data });
      }
    };
    const mappedQueryOptions = {
      onSuccess: onGlobalSuccess,
    };
    const reactQueryOptions = {
      ...defaultQueryOptions,
      ...pickBy(mappedQueryOptions),
    };

    const queryResult = isMutation ? queryFn(async (vars) => request(vars), reactQueryOptions) : queryFn(queryKey, request, reactQueryOptions);
    const result = {
      filters: { ...resolvedFilters, ...additionalFilters },
      requestParams,
      ...queryResult,
    };

    useEffect(() => {
      if (result.data) {
        onResolveFilters(result.data);
      }
    }, [result.data]);

    return result;
  };
