import React, { useState } from "react";
import RCreatableSelect from "react-select/creatable";
import { customStyles as originalStyles, customTheme } from "./styles";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const customStyles = {
  ...originalStyles,
  clearIndicator: (provided) => ({ ...provided, marginRight: 0 }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "rgba(244, 246, 248, 1)",
    padding: 6,
    minHeight: 32,
    borderRadius: 6,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: "rgba(244, 246, 248, 1)",
    color: "rgba(17, 24, 36, 1)",
    fontSize: 14,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    fontSize: 14,
    color: "rgba(163, 169, 177, 1)",
    ":hover": {
      backgroundColor: "rgba(244, 246, 248, 1)",
    },
  }),
};

// Allow users to pass props to prevent certain inputs being recorded
const CreatableSelect = ({ value: originalValue = [], onChange, ...props }) => {
  const [value, setValue] = useState(originalValue);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (v) => {
    setValue(v);
    if (onChange) onChange(v);
  };
  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab": {
        const newValue = [...value, createOption(inputValue)];
        handleChange(newValue);
        setInputValue("");
        event.preventDefault();
        break;
      }
      default:
        break;
    }
  };

  const handleInputChange = (inputV) => {
    setInputValue(inputV);
  };

  const selectValues = originalValue.length ? originalValue : value;

  return <RCreatableSelect components={components} isClearable isMulti menuIsOpen={false} inputValue={inputValue} onKeyDown={handleKeyDown} onInputChange={handleInputChange} onChange={handleChange} theme={customTheme} styles={customStyles} value={selectValues} {...props} />;
};

export default CreatableSelect;
