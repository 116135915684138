import React from "react";
import styled from "styled-components";
import BaseComponent from "../Base/BaseComponent";
import Box from "../Box/Box";
import LogEvent from "../../log/LogEvent";

const Input = styled(BaseComponent)`
  box-sizing: border-box;
`;

const InputField = ({ startIcon, endIcon, width, maxWidth, logEventProps, ...props }) => (
  <LogEvent logEventProps={logEventProps} actionProps={{ onClick: { action: "typing" } }} elementType="input">
    <Box position="relative" width={width} maxWidth={maxWidth}>
      {startIcon && (
        <Box position="absolute" top="12px" left="12px">
          {startIcon}
        </Box>
      )}
      <Input py="12px" pl={startIcon ? "36px" : "12px"} pr={endIcon ? "36px" : "12px"} {...props} width="100%" />
      {endIcon && (
        <Box position="absolute" top="12px" right="12px">
          {endIcon}
        </Box>
      )}
    </Box>
  </LogEvent>
);

InputField.defaultProps = {
  border: "solid",
  borderWidth: 1,
  borderColor: "#D5D9DE",
  borderRadius: 8,
  fontSize: 14,
  fontFamily: "Inter, sans-serif",
  fontWeight: 400,
  color: "fullShade",
  as: "input",
  width: 350,
};

export default InputField;
