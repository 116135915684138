import React, { useEffect } from "react";
import { Checkbox as RCheckbox, Label as RLabel } from "@rebass/forms";
import styled from "styled-components";
import PropTypes from "prop-types";

import BaseComponent from "../Base/BaseComponent";
import { Text } from "../Text/Text";

import { useControlledState } from "../../hooks/useControlledState";

const StyledCheckbox = styled(RCheckbox)`
  cursor: pointer;
  input:focus ~ && {
    background-color: transparent;
  }
  input:checked ~ && {
    color: ${({ theme, color }) => theme.colors[color]};
  }
`;

const Label = styled(BaseComponent)`
  cursor: pointer;
`;

Label.defaultProps = {
  alignItems: "center",
  as: RLabel,
};

/**
 * Checkbox is an uncontrolled component by default. If you intend to use it as controlled component, pass the proper onChange handler and checked/defaultChecked props
 *
 * **CheckboxGroup** provides easy to use api and handles event propagation. if you are using multiple checkboxes in your project, you can group them under CheckboxGroup and have a central way of managing states
 * CheckboxGroup can be used in two ways:
 *
 * 1. Passing onChange handler to listen to changes in value **(Uncontrolled component)**
 * 2. Passing both onChange and value to convert it to controlled component **(Controlled component)**
 *
 */

const Checkbox = ({ label, labelProps, renderLabel, textProps, ...props }) => {
  return (
    <Label width="auto" {...labelProps}>
      <StyledCheckbox {...props} />
      {renderLabel ? renderLabel(label) : <Text {...textProps}>{label}</Text>}
    </Label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.object,
};

Checkbox.defaultProps = {
  labelProps: {},
};

const CheckboxGroup = ({ children, value, onChange: onHandleChange }) => {
  const [checkboxState, onChange] = useControlledState(value, {}, onHandleChange);

  if (typeof checkboxState !== "object" || checkboxState === null) {
    console.warn(`CheckboxGroup Error: value/default value must be an object.`);
  }

  const handleChange = (label) => (e) => {
    onChange({ ...checkboxState, [label]: e.target.checked }, label);
  };

  useEffect(() => {
    if (!value) {
      React.Children.map(children, (child) => {
        const { checked, defaultChecked, label, name } = child.props;
        checkboxState[name || label] = checked || defaultChecked || false;
      });
    }
  }, []);

  const content = React.Children.map(children, (child) => {
    if (child.type.name === Checkbox.name) {
      const { label, name } = child.props;
      const checkboxProps = {};
      if (value && Object.values(value).length !== 0) {
        checkboxProps.checked = !!value[name || label];
      }
      return React.cloneElement(child, {
        onChange: handleChange(child.props.name || child.props.label),
        ...checkboxProps,
      });
    }
    console.warn("CheckboxGroup should only contain checkbox components");
    return null;
  });
  return content;
};

export { Checkbox, CheckboxGroup, Label };
