import React, { useEffect } from "react";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

import PropTypes from "prop-types";
import { PrimaryButton } from "../Button/PrimaryButton";
import { StyledModal, StyledOverlay, StyledContainer } from "./dialog.styles";
import Box from "../Box/Box";
import Flex from "../Flex/Flex";
import { H2 } from "../Text/Text";
import FAIcon from "../Icon/FAIcon";
import { SecondaryOutlinedButton } from "../Button/SecondaryButton";
import "../../utils/style.css";

/**
 * Dialog modal component.
 * @param open
 * @param onClose
 * @param renderHeader
 * @param renderFooter
 * @param type
 * @param title
 * @param footerOverrides
 * @param isLoading
 * @param isFormValid
 * @param style
 * @param headerProps
 * @param containerProps
 * @param bodyProps
 * @param footerProps
 * @param overlayProps
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const Dialog = ({ open, onClose, renderHeader, renderFooter, type, title, footerOverrides, isLoading, isFormValid, style, headerProps, containerProps, bodyProps, footerProps, overlayProps, children }) => {
  const onCloseModal = () => {
    if (isLoading) return;
    onClose(false);
  };

  useEffect(() => {
    if (!footerOverrides && type !== "basic" && !renderFooter) {
      throw new Error(`Dialog with explicit type ['${type}'] requires to pass (footerOverrides) values and does not need (renderFooter).`);
    }
    const close = (e) => {
      if (e.keyCode === 27) {
        onCloseModal();
      }
    };
    window.addEventListener("keydown", close);
    window.addEventListener("mouseup", () => {});

    return () => {
      window.removeEventListener("keydown", close);
      window.removeEventListener("mouseup", () => {});
    };
  }, []);

  const Header = ({ title: label }) => {
    if (renderHeader && (!type || type === "prompt" || type === "confirmation")) {
      return renderHeader();
    }

    switch (type) {
      case "basic":
        return <></>;
      default:
        return (
          <Box mb={2} onClick={(e) => e.stopPropagation()} {...headerProps}>
            <Flex justifyContent="space-between" alignItems="center" onClick={(e) => e.stopPropagation()}>
              <H2>{label}</H2>
              <Box
                cursor="pointer"
                onClick={() => {
                  onCloseModal();
                }}
              >
                <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
              </Box>
            </Flex>
          </Box>
        );
    }
  };

  const Footer = () => {
    if (renderFooter && (type === "prompt" || type === "confirmation")) {
      return renderFooter();
    }

    switch (type) {
      case "confirmation":
        return (
          <Box mt={4} {...footerProps}>
            <Flex justifyContent="end" alignItems="center">
              <PrimaryButton
                type="submit"
                disabled={!isFormValid || isLoading}
                onClick={(e) => {
                  footerOverrides.actions[0]();
                  e.stopPropagation();
                }}
              >
                {footerOverrides.labels[0]}
              </PrimaryButton>
            </Flex>
          </Box>
        );
      case "prompt":
        return (
          <Box mt={4} {...footerProps}>
            <Flex justifyContent="flex-end">
              <SecondaryOutlinedButton
                onClick={() => {
                  if (footerOverrides.actions[0] && typeof footerOverrides.actions[0] === "function") {
                    footerOverrides.actions[0]();
                  }
                  onCloseModal();
                }}
                mr={2}
              >
                {footerOverrides.labels[0]}
              </SecondaryOutlinedButton>
              <PrimaryButton
                type="submit"
                disabled={!isFormValid || isLoading}
                onClick={(e) => {
                  footerOverrides.actions[1]();
                  e.stopPropagation();
                }}
              >
                {footerOverrides.labels[1]}
              </PrimaryButton>
            </Flex>
          </Box>
        );
      default:
        return <></>;
    }
  };

  const Body = (props) => (
    <Box onClick={(e) => e.stopPropagation()} {...props}>
      {children}
    </Box>
  );

  const handleClickOverlay = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onCloseModal();
  };

  const Modal = ({ children: modalChildren, ...props }) => <StyledModal {...props}>{modalChildren}</StyledModal>;

  const Container = ({ children: containerChildren, ...props }) => (
    <StyledContainer className="scrollbar-invisible" {...props}>
      {containerChildren}
    </StyledContainer>
  );

  return (
    <>
      <StyledOverlay
        hidden={!open}
        tabindex="-1"
        onKeyUp={(e) => {
          if (e.keyCode === 27) {
            handleClickOverlay(e);
          }
        }}
        onClick={(e) => {
          handleClickOverlay(e);
        }}
        {...overlayProps}
      >
        <Modal style={{ ...style }} onClick={(e) => e.stopPropagation()}>
          <Container {...containerProps}>
            <Header closeModal={onCloseModal} title={title} />
            <Body {...bodyProps} />
            <Footer />
          </Container>
        </Modal>
      </StyledOverlay>
    </>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func,
  type: PropTypes.oneOf(["basic", "confirmation", "prompt"]),
  title: PropTypes.string,
  footerOverrides: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    actions: PropTypes.arrayOf(PropTypes.func),
  }),
  isFormValid: PropTypes.bool,
  isLoading: PropTypes.bool,
  style: PropTypes.object,
  headerProps: PropTypes.object,
  containerProps: PropTypes.object,
  bodyProps: PropTypes.object,
  footerProps: PropTypes.object,
  overlayProps: PropTypes.object,
};

Dialog.defaultProps = {
  renderHeader: null,
  renderFooter: null,
  title: "",
  type: "basic",
  footerOverrides: null,
  isFormValid: true,
  isLoading: false,
  headerProps: {},
  containerProps: {},
  footerProps: {},
  bodyProps: {},
  overlayProps: {},
  style: {},
};

export default Dialog;
