import React, { useState } from "react";
import _ from "lodash";
import { getHours, getMinutes, getSeconds, getMilliseconds, getDay, getMonth, getYear, addMonths, addYears, subMonths, subYears, setHours, setMinutes, setSeconds, setMilliseconds, setDate, setYear, setMonth, getDaysInMonth, getWeek, getDate, isDate, isSameDay, format } from "date-fns";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import Box from "../../Box/Box";
import Flex from "../../Flex/Flex";
import Chip from "../../Chip/Chip";
import Paper from "../../Paper/Paper";
import OutlinedButton from "../../Button/OutlinedButton";
import { Text } from "../../Text/Text";
import FAIcon from "../../Icon/FAIcon";
import LogEvent from "../../../log/LogEvent";

export const defaultDays = [
  { id: 0, label: "Su", display: "Sunday" },
  { id: 1, label: "Mo", display: "Monday" },
  { id: 2, label: "Tu", display: "Tuesday" },
  { id: 3, label: "We", display: "Wednesay" },
  { id: 4, label: "Th", display: "Thursday" },
  { id: 5, label: "Fr", display: "Friday" },
  { id: 6, label: "Sa", display: "Saturday" },
];

export const defaultMonths = [
  { id: 0, label: "Jan", display: "January" },
  { id: 1, label: "Feb", display: "Febuary" },
  { id: 2, label: "Mar", display: "March" },
  { id: 3, label: "Apr", display: "April" },
  { id: 4, label: "May", display: "May" },
  { id: 5, label: "Jun", display: "June" },
  { id: 6, label: "Jul", display: "July" },
  { id: 7, label: "Aug", display: "August" },
  { id: 8, label: "Sep", display: "September" },
  { id: 9, label: "Oct", display: "October" },
  { id: 10, label: "Nov", display: "November" },
  { id: 11, label: "Dec", display: "December" },
];

const PickerTitle = ({ label, handleOnClick, handleForward, handleBackward, logEventProps }) => (
  <LogEvent logEventProps={logEventProps} actionProps={{ onClick: { action: "click" } }} elementType="calendar">
    <Flex m={1} justifyContent="space-between" alignItems="center">
      <OutlinedButton borderWidth={0} onClick={handleBackward}>
        <FAIcon icon={faChevronLeft} color="darkestShade" fontSize="14px" />
      </OutlinedButton>
      {label && (
        <OutlinedButton borderWidth={0} onClick={handleOnClick}>
          <Text fontWeight="600" color="grey" fontSize={2}>
            {label}
          </Text>
        </OutlinedButton>
      )}
      <OutlinedButton borderWidth={0} onClick={handleForward}>
        <FAIcon icon={faChevronRight} color="darkestShade" fontSize="14px" />
      </OutlinedButton>
    </Flex>
  </LogEvent>
);

const MonthCalendar = ({ virtualDate, selectedDate, handleMonthClick, checkValidMonth, allMonths = defaultMonths }) => {
  const handleClick = (month) => {
    if (checkValidMonth) {
      if (!checkValidMonth(setMonth(virtualDate, month.id))) {
        return handleMonthClick(month);
      }
    }
    return handleMonthClick(month);
  };

  const getTextColor = (month) => {
    if (_.isEqual(month.id, getMonth(selectedDate)) && _.isEqual(getYear(virtualDate), getYear(selectedDate))) {
      return "primary";
    }

    if (checkValidMonth && checkValidMonth(setMonth(virtualDate, month.id))) {
      return "mediumShade";
    }

    return "grey";
  };

  return _.chain(allMonths)
    .chunk(3)
    .map((months, index) => (
      <Flex mb={2} key={index} justifyContent="space-between" alignItems="center">
        {_.map(months, (month) => (
          <OutlinedButton key={month.id} borderWidth={0} height={40} width={80} onClick={() => handleClick(month)}>
            <Text fontSize={2} fontWeight="normal" color={getTextColor(month)}>
              {month.label}
            </Text>
          </OutlinedButton>
        ))}
      </Flex>
    ))
    .value();
};

const YearCalendar = ({ virtualDate, selectedDate, handleYearClick, checkValidYear }) => {
  const selectedYear = getYear(virtualDate);

  const handleClick = (year) => {
    if (checkValidYear) {
      if (!checkValidYear(setYear(virtualDate, year))) {
        return handleYearClick(year);
      }
    }
    return handleYearClick(year);
  };

  const getTextColor = (year) => {
    if (_.isEqual(year, getYear(selectedDate))) {
      return "primary";
    }

    if (checkValidYear && checkValidYear(setYear(virtualDate, year))) {
      return "mediumShade";
    }

    return "grey";
  };

  return _.chain(_.range(selectedYear - 6, selectedYear + 6))
    .chunk(3)
    .map((years, index) => (
      <Flex mb={2} key={index} justifyContent="space-between" alignItems="center">
        {_.map(years, (year) => (
          <OutlinedButton key={year} borderWidth={0} height={40} width={80} onClick={() => handleClick(year)}>
            <Text fontSize={2} fontWeight="normal" color={getTextColor(year)}>
              {year}
            </Text>
          </OutlinedButton>
        ))}
      </Flex>
    ))
    .value();
};

const DayComponent = ({ day, label, selected = false, disabled = false, highlighted = false, setSelected }) => {
  const [hover, setHover] = useState(false);

  const getBgColor = () => {
    if (selected) {
      return "primary";
    }

    if (!label || !hover) {
      return "transparent";
    }

    return "lightShade";
  };

  const getTextColor = () => {
    if (selected) {
      return "white";
    }

    if (highlighted) {
      return "primary";
    }

    if (disabled) {
      return "mediumShade";
    }

    return "grey";
  };

  const bgColor = getBgColor();

  return (
    <Flex onClick={() => setSelected(day)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Chip bg={bgColor} cursor={label ? "pointer" : "unset"} color={getTextColor()} fontSize={2} fontWeight="normal" width="20px">
        {label}
      </Chip>
    </Flex>
  );
};

const WeekCalendar = ({ selectedDate, virtualDate, handleDayClick, checkWithin, checkValidDay, allDays = defaultDays }) => (
  <Box m={2}>
    <Flex mb={2} justifyContent="space-between" alignItems="center">
      {_.map(allDays, (day) => (
        <Text key={day.id} fontSize={1} fontWeight="500" color="grey" width="20px">
          {day.label}
        </Text>
      ))}
    </Flex>
    {_.chain(_.range(getDaysInMonth(virtualDate))) // generate all the days in month
      .map((dayIndex) => setDate(virtualDate, dayIndex + 1)) // change it to date type
      .groupBy((date) => getWeek(date))
      .values()
      .sortBy((days) => getDate(days[0])) // Ensure the order of weeks for month is in correct order
      .map((days, index) => (
        <Flex key={index} mb={1} justifyContent="space-between" alignItems="center">
          {_.chain(allDays)
            .groupBy((day) => day.id)
            .assignIn(_.groupBy(days, (day) => getDay(day)))
            .map((value) => (isDate(value[0]) ? value[0] : null))
            .values()
            .map((day, i) => <DayComponent key={i} day={day} label={getDate(day)} selected={isSameDay(selectedDate, day)} disabled={checkValidDay ? checkValidDay(day) : false} highlighted={checkWithin ? checkWithin(day) : false} setSelected={(newDate) => handleDayClick(newDate)} />)
            .value()}
        </Flex>
      ))
      .value()}
  </Box>
);

const Calendar = ({ defaultDate, onChange, checkWithin, checkValidDay, checkValidMonth, checkValidYear, ...props }) => {
  const [view, setView] = useState("week");
  const [virtualDate, setVirtualDate] = useState(defaultDate); // virtual date for compoent to move the date forward or backward
  const { allMonths = defaultMonths } = props;

  const handleDayClick = (newDate) => {
    // ensure the time is same as default date
    let dateWithTime = newDate;
    dateWithTime = setHours(dateWithTime, getHours(defaultDate));
    dateWithTime = setMinutes(dateWithTime, getMinutes(defaultDate));
    dateWithTime = setSeconds(dateWithTime, getSeconds(defaultDate));
    dateWithTime = setMilliseconds(dateWithTime, getMilliseconds(defaultDate));

    if (onChange) {
      onChange(dateWithTime);
    }
  };

  const handleMonthClick = (month) => {
    setVirtualDate(setMonth(virtualDate, month.id));
    setView("week");
  };

  const handleYearClick = (year) => {
    setVirtualDate(setYear(virtualDate, year));
    setView("month");
  };

  const getTitle = () => {
    const yearDisplay = format(virtualDate, "yyyy");
    const monthDisplay = _.chain(allMonths)
      .find((month) => _.isEqual(month.id, getMonth(virtualDate)))
      .get("display")
      .value();

    return `${monthDisplay} ${yearDisplay}`;
  };
  return (
    <Paper elevation={1} width={320} {...props}>
      {_.isEqual(view, "week") && (
        <Box p={1}>
          <PickerTitle label={getTitle()} handleOnClick={() => setView("month")} handleForward={() => setVirtualDate(addMonths(virtualDate, 1))} handleBackward={() => setVirtualDate(subMonths(virtualDate, 1))} />
          <WeekCalendar selectedDate={defaultDate} virtualDate={virtualDate} handleDayClick={handleDayClick} checkWithin={checkWithin} checkValidDay={checkValidDay} {...props} />
        </Box>
      )}
      {_.isEqual(view, "month") && (
        <Box p={1}>
          <PickerTitle label={format(virtualDate, "yyyy")} handleOnClick={() => setView("year")} handleForward={() => setVirtualDate(addYears(virtualDate, 1))} handleBackward={() => setVirtualDate(subYears(virtualDate, 1))} />
          <MonthCalendar virtualDate={virtualDate} selectedDate={defaultDate} handleMonthClick={handleMonthClick} checkValidMonth={checkValidMonth} {...props} />
        </Box>
      )}
      {_.isEqual(view, "year") && (
        <Box p={1}>
          <PickerTitle handleForward={() => setVirtualDate(addYears(virtualDate, 12))} handleBackward={() => setVirtualDate(subYears(virtualDate, 12))} />
          <YearCalendar virtualDate={virtualDate} selectedDate={defaultDate} handleYearClick={handleYearClick} checkValidYear={checkValidYear} {...props} />
        </Box>
      )}
    </Paper>
  );
};

export default Calendar;
