import React from "react";
import { Text as RText } from "rebass/styled-components";
import BaseComponent from "../Base/BaseComponent";
import headingStyles from "./headingStyles";
import LogEvent from "../../log/LogEvent";

const Text = (props) => <BaseComponent data-testid={props.testid} {...props} />;

Text.defaultProps = {
  color: "fullShade",
  as: RText,
};

const Body = ({ medium, small, extraSmall, ...props }) => <Text fontWeight={medium ? "medium" : "regular"} fontSize={extraSmall ? 12 : small ? 14 : 16} lineHeight="24px" letterSpacing="-0.08px" {...props} />;

const createHeadingComponent = (name) => {
  const Component = (props) => (
    <LogEvent logEventProps={props.logEventProps} actionProps={{ onClick: { action: "click" } }} elementType="Text">
      <Text {...headingStyles[name]} {...props} />
    </LogEvent>
  );
  Component.displayName = name;
  Component.propTypes = Text.propTypes;
  return Component;
};

const H1 = createHeadingComponent("H1");
const H2 = createHeadingComponent("H2");
const H3 = createHeadingComponent("H3");
const H4 = createHeadingComponent("H4");
const H5 = createHeadingComponent("H5");
const H6 = createHeadingComponent("H6");

export { Text, Body, H1, H2, H3, H4, H5, H6 };
