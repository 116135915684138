import React, { useRef, useMemo, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { get, isEmpty } from "lodash";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";

import { TableContainer, TableHead, TableRow as BTableRow, TableCell as BTableCell, TableHeadCell, TableBody } from "./Table";
import SortArrow from "./SortArrow";

import FAIcon from "../Icon/FAIcon";
import Flex from "../Flex/Flex";
import { Text } from "../Text/Text";
import Box from "../Box/Box";
import { SecondaryOutlinedButton } from "../Button/SecondaryButton";
import theme from "../../theme/theme";

import NoResults from "./NoResults";

const LoadMoreButton = styled(SecondaryOutlinedButton)`
  border-radius: 8px;
  height: 40px;
`;

const HeaderColumn = styled(Flex)`
  cursor: pointer;
  align-items: center;
`;

const TableCell = styled(BTableCell)`
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const TableRow = styled(BTableRow)`

  overflow: hidden;
  tbody > &:hover {
    background: #f4f6f8;
  }
  tbody > & {
    box-shadow: inset 0px 1px 0px #d5d9de;
    &:hover, &:hover + tr {
      box-shadow: none;
    }

`;

const defaultPropGetter = () => ({});

const defaultHeaderProps = ({ width, maxWidth, toggleSortBy, isSortedDesc, canSort }, props) => ({
  style: {
    width,
    maxWidth,
    ...get(props, "style", {}),
  },
  onClick: () => (canSort ? toggleSortBy(!isSortedDesc) : null),
  ...props,
});

const renderDefaultTable = ({
  rows,
  prepareRow,
  tableStyle,
  // onFetchNextPage,
  getTableProps,
  getTableBodyProps,
  getHeaderProps,
  getHeaderGroupProps = defaultPropGetter,
  getRowProps,
  getColumnProps,
  getCellProps,
  headerGroups,
  headerCellProps,
  onRowClick,
  containerStyle = {},
}) => (
  <Box style={containerStyle}>
    <TableContainer width="100%" minWidth={theme.breakpoints[2]} textAlign="left" {...getTableProps()} style={tableStyle}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()} {...getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeadCell py={2} {...column.getHeaderProps([defaultHeaderProps(column, getHeaderProps(column)), getColumnProps(column)])}>
                <HeaderColumn {...headerCellProps}>
                  <Box>
                    <Text color="darkestShade" fontWeight={600} fontSize={14} cursor={column.canSort ? "pointer" : "default"}>
                      {column.render("Header")}
                    </Text>
                  </Box>
                  {!isEmpty(rows) && column.canSort && (
                    <Box pl="5px">
                      <SortArrow active={column.isSorted && !column.isSortedDesc} />
                      <SortArrow down active={column.isSorted && column.isSortedDesc} />
                    </Box>
                  )}
                </HeaderColumn>
              </TableHeadCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps(getRowProps(row))} isSelected={row.isSelected} onClick={() => (onRowClick ? onRowClick(row) : row.toggleRowSelected())}>
              {row.cells.map((cell) => (
                <TableCell {...cell.getCellProps([getColumnProps(cell.column), getCellProps(cell)])}>{cell.render("Cell")}</TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </TableContainer>
  </Box>
);

const renderDefaultRowSelectInfo = (props) => {
  const { selectedFlatRows, toggleAllRowsSelected } = props;

  return (
    <Box zIndex="1" bg="lightBlue" p={2} bottom="20px" right="20px" left="300px">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <Text m={1} textAlign="center" color="primary" fontWeight="bold">
            {`${selectedFlatRows.length} selected`}
          </Text>
          <SecondaryOutlinedButton bg="white" onClick={() => toggleAllRowsSelected(false)}>
            Deselect
          </SecondaryOutlinedButton>
        </Flex>
      </Flex>
    </Box>
  );
};

const renderDefaultRowSelectHeader = (props) => (
  <div>
    <IndeterminateCheckbox {...props} />
  </div>
);

const renderDefaultRowSelectCell = (props) => {
  return (
    <div>
      <IndeterminateCheckbox {...props} />
    </div>
  );
};

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const TableLayout = (props) => {
  const { onFetchNextPage, rows, isFetchingNextPage, hasNextPage, loadMoreText, renderTable, renderRowSelectInfo, enableRowSelect, noResultsProps, selectedFlatRows } = props;
  return (
    <>
      {renderTable ? renderTable(props) : renderDefaultTable(props)}
      {enableRowSelect && !isEmpty(selectedFlatRows) && (renderRowSelectInfo ? renderRowSelectInfo(props) : renderDefaultRowSelectInfo(props))}
      {isEmpty(rows) && <NoResults {...noResultsProps} />}
      {hasNextPage && (
        <Flex flex={1} alignItems="center" justifyContent="center" mt={2} mb={3}>
          <LoadMoreButton
            logEventProps={{
              subSource: props.logEventProps.subSource,
              eventName: "LOAD_MORE",
              page: props.logEventProps.page,
            }}
            onClick={onFetchNextPage}
            disabled={isFetchingNextPage}
          >
            <Text color="darkestShade" fontSize={14} fontWeight={600}>
              {!isFetchingNextPage && (loadMoreText || "Load more")}
              {isFetchingNextPage && "Loading..."}
            </Text>
          </LoadMoreButton>
        </Flex>
      )}
    </>
  );
};

const DataTable = ({ columns: tableColumns, data: tableData, initialSortBy, getHeaderProps = defaultPropGetter, getColumnProps = defaultPropGetter, getRowProps = defaultPropGetter, getCellProps = defaultPropGetter, hasNextPage, onFetchData, onFetchNextPage: fetchNextPage, isFetchingNextPage, onRowClick, onSelectedRow, loadMoreText, renderTable, logEventProps, renderRowSelectHeader, renderRowSelectCell, enableRowSelect = false, renderRowSelectInfo, enableRowEdit = false, renderRowEdit, ...props }) => {
  const [columns, data] = useMemo(() => [tableColumns, tableData], [tableData]);
  const getRowId = (row, relativeIndex) => (row ? row.uid : relativeIndex);
  const pageYOffset = useRef(window.pageYOffset);
  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: [initialSortBy],
        pageSize: 5,
        pageIndex: 0,
      },
      getRowId,
      ...props,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((_columns) => {
        let finalColumns = _columns;

        if (enableRowSelect) {
          finalColumns = [
            // Make a column for selection
            {
              id: "selection",
              width: "10px",
              Header: ({ getToggleAllRowsSelectedProps }) => (renderRowSelectHeader ? renderRowSelectHeader(getToggleAllRowsSelectedProps()) : renderDefaultRowSelectHeader(getToggleAllRowsSelectedProps())),
              Cell: ({ row }) => (renderRowSelectCell ? renderRowSelectCell(row.getToggleRowSelectedProps()) : renderDefaultRowSelectCell(row.getToggleRowSelectedProps())),
            },
            ..._columns,
          ];
        }

        if (enableRowEdit) {
          finalColumns.push({
            id: "edit",
            width: "5px",
            Cell: ({ row }) => (renderRowEdit ? renderRowEdit(row) : <FAIcon icon={faEllipsisV} />),
          });
        }

        return finalColumns;
      });
    }
  );
  const {
    state: { sortBy, pageSize, pageIndex, selectedRowIds },
  } = tableInstance;

  const onFetchNextPage = async () => {
    pageYOffset.current = window.pageYOffset;
    await fetchNextPage();
    window.scroll({ top: pageYOffset.current });
  };

  useEffect(() => {
    onFetchData({ pageIndex, pageSize, sortBy });
  }, [sortBy, pageIndex, pageSize]);

  useEffect(() => {
    onSelectedRow({ selectedRowIds });
  }, [selectedRowIds]);

  return (
    <TableLayout
      logEventProps
      {...tableInstance}
      {...{
        getHeaderProps,
        getColumnProps,
        getRowProps,
        getCellProps,
        hasNextPage,
        isFetchingNextPage,
        onFetchNextPage,
        onRowClick,
        loadMoreText,
        renderTable,
        renderRowSelectInfo,
        enableRowSelect,
      }}
    />
  );
};

DataTable.propTypes = {
  renderRow: PropTypes.func,
  onFetchData: PropTypes.func,
  onSelectedRow: PropTypes.func,
};

DataTable.defaultProps = {
  renderRow: () => {},
  onFetchData: () => {},
  onSelectedRow: () => {},
};

export default DataTable;
