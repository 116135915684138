import React, { useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import BaseComponent from "../Base/BaseComponent";

import { useControlledState } from "../../hooks/useControlledState";

const TooltipContainer = styled(BaseComponent)`
  cursor: default;
`;

const TooltipElement = styled(BaseComponent)`
  background: rgba(0, 0, 0, 0.8);
`;

const Tooltip = ({ children, trigger, toolTipElementProps = {}, options = {} }) => {
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(trigger, popperElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
    ...options,
  });

  return (
    <TooltipElement color="white" maxWidth={220} px={1} py={1} borderRadius={8} ref={setPopperElement} style={styles.popper} {...toolTipElementProps} {...attributes.popper}>
      {children}
    </TooltipElement>
  );
};

export default ({ children, tooltip, show: isShown, onChange, toolTipElementProps = {}, toolTipOptions = {}, ...props }) => {
  const [trigger, setTrigger] = useState(null);
  const [show, setShow] = useControlledState(isShown, false, onChange);

  return (
    <TooltipContainer ref={setTrigger} {...props} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      {children}
      {show && (
        <Tooltip trigger={trigger} options={toolTipOptions} toolTipElementProps={toolTipElementProps}>
          {tooltip}
        </Tooltip>
      )}
    </TooltipContainer>
  );
};
