import React, { useState } from "react";
import _ from "lodash";
import Box from "../Box/Box";
import Flex from "../Flex/Flex";
import { Text } from "../Text/Text";
import LogEvent from "../../log/LogEvent";

import InputField from "./InputField";

const Input = ({ label, labelProps, labelTextProps, labelRight, placeholder, value = "", onChange, hintLabel, errorLabel, as, disabled = false, inputLimit = null, logEventProps, ...props }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleOnChange = (event) => {
    if (_.isNumber(inputLimit) && _.gt(_.size(event.target.value), inputLimit)) {
      return null;
    }

    setInputValue(event.target.value);
    if (onChange) {
      return onChange(event);
    }

    return null;
  };

  const DefaultHintLabel = () => {
    if (_.isNumber(inputLimit)) {
      return (
        <Text color="darkestShade" fontSize={1} {...labelTextProps}>
          {`${_.size(inputValue)}/${inputLimit} characters`}
        </Text>
      );
    }
    return null;
  };

  return (
    <LogEvent logEventProps={logEventProps} actionProps={{ onClick: { action: "typing" } }} elementType="input">
      <Box {...props}>
        <Flex mt={2} justifyContent="space-between" alignItems="center" {...labelProps}>
          <Text fontSize={1} fontWeight="bold" color="darkestShade" {...labelTextProps}>
            {label}
          </Text>
          {labelRight && (
            <Flex justifyContent="right" alignItems="center">
              <Text color="darkestShade" fontSize={1} {...labelTextProps}>
                {labelRight}
              </Text>
            </Flex>
          )}
        </Flex>
        <InputField mt={1} width="100%" disabled={disabled} placeholder={placeholder} value={inputValue} onChange={handleOnChange} as={_.isUndefined(as) ? "input" : as} style={{ resize: "none" }} />
        <Flex justifyContent="space-between" mt={1}>
          <Text color="danger" fontSize={1}>
            {errorLabel}
          </Text>
          {hintLabel || <DefaultHintLabel />}
        </Flex>
      </Box>
    </LogEvent>
  );
};

export default Input;
