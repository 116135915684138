import React, { useMemo, createContext } from "react";
import ApiClient from "./Client";
import QueryResolver from "./QueryResolver";
import { useAuth } from "./AuthProvider";

export const ApiContext = createContext();

export default ({ children, client, endpoint, showStaticData, queryMapping }) => {
  const { authState, sessionQueryKey } = useAuth();
  const apiClient =
    client ||
    useMemo(
      () =>
        new ApiClient(
          endpoint,
          {
            "X-SESSION": authState.token || process.env.REACT_APP_DUMMY_SESSION_TOKEN || new URLSearchParams(window.location.search).get("sessionToken") || new URLSearchParams(window.location.search).get("session"),
          },
          showStaticData
        ),
      [authState.token]
    );

  return (
    <ApiContext.Provider
      value={{
        showStaticData,
        auth: authState,
        client: apiClient,
        queryResolver: QueryResolver.fromQueries(queryMapping),
        sessionQueryKey,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
