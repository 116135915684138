import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import { format } from "date-fns";

import Box from "../Box/Box";
import Flex from "../Flex/Flex";
import Paper from "../Paper/Paper";
import LogEvent from "../../log/LogEvent";
import { PrimaryButton } from "../Button/PrimaryButton";
import Calendar from "../Widgets/Calendar/Calendar";
import CalendarWithTime from "../Widgets/Calendar/CalendarWithTime";
import DigitalTime from "../Widgets/Time/Time";

import { useControlledState } from "../../hooks/useControlledState";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";

const MODIFIERS = {
  OFFSET: {
    name: "offset",
    options: {
      offset: [10, 10],
    },
  },
  OFFSET_CENTERED: {
    name: "offset",
    options: {
      offset: [0, 10],
    },
  },
};

const DateInputField = ({ defaultDate, onlyDate = false, onlyTime = false, disabled = false, open: isOpen, onOpenChange, isContentCentered = false, dropdownRef, popperStyle = {}, logEventProps, containerProps, onChange, ...props }) => {
  const [date, setDate] = useState(defaultDate);
  const [open, setOpen] = useControlledState(isOpen, false, onOpenChange);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto",
    modifiers: isContentCentered ? [MODIFIERS.OFFSET_CENTERED] : [MODIFIERS.OFFSET],
  });

  const [isActive, setIsActive] = useDetectOutsideClick(referenceElement, popperElement, open);

  const handleOpen = () => {
    if (!disabled) {
      if (!defaultDate) {
        const newDate = new Date();
        setDate(newDate);

        if (onChange) {
          onChange(newDate);
        }
      }
      setOpen(true);
      setIsActive(true);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, setOpen]);

  useEffect(() => {
    if (!isActive) {
      setOpen(false);
    }
  }, [isActive]);

  useEffect(() => {
    if (dropdownRef) {
      dropdownRef({ setOpen, open });
    }
  }, [open]);

  const getDisplayFormat = () => {
    if (onlyDate) {
      return "dd LLL yyyy";
    }

    if (onlyTime) {
      return "hh:mm a";
    }

    return "dd LLL yyyy, hh:mm a";
  };

  const handleOnChange = (newDate) => {
    setDate(newDate);
    if (onChange) {
      onChange(newDate);
    }
  };

  return (
    <LogEvent logEventProps={logEventProps} actionProps={{ onClick: { action: "click" } }} elementType="calendar">
      <Box
        p="12px"
        minHeight="16px"
        cursor={disabled ? "cursor" : "pointer"}
        border="solid"
        borderWidth={1}
        borderColor="mediumShade"
        borderRadius={8}
        ref={setReferenceElement}
        bg={disabled ? "#EFEFEF4D" : "transparent"}
        onClick={(event) => {
          event.stopPropagation();
          handleOpen();
        }}
        {...containerProps}
      >
        {defaultDate && format(defaultDate, getDisplayFormat())}
      </Box>
      {open && (
        <Box
          ref={setPopperElement}
          style={{
            ...styles.popper,
            zIndex: 1,
            minWidth: isContentCentered ? (referenceElement && referenceElement.scrollWidth) || 0 : 0,
            ...popperStyle,
          }}
          {...attributes.popper}
          {...props}
        >
          <Paper elevation={1} width={320} bg="white" {...props}>
            {onlyDate && <Calendar bg="white" elevation={0} defaultDate={date} onChange={handleOnChange} />}

            {onlyTime && <DigitalTime elevation={0} defaultDate={date} onChange={handleOnChange} />}

            {!onlyTime && !onlyDate && <CalendarWithTime bg="white" elevation={0} defaultDate={date} onChange={handleOnChange} />}
            <Flex pt={0} pb={2} mr={2} justifyContent="right">
              <PrimaryButton onClick={() => setOpen(false)}>Apply</PrimaryButton>
            </Flex>
          </Paper>
        </Box>
      )}
    </LogEvent>
  );
};

export default DateInputField;
