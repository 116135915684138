import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import _ from "lodash";
import { faAngleDoubleRight, faAngleDoubleLeft } from "@fortawesome/pro-regular-svg-icons";
import LogEvent from "../../../log/LogEvent";

import Box from "../../Box/Box";
import Flex from "../../Flex/Flex";
import FAIcon from "../../Icon/FAIcon";
import { Body } from "../../Text/Text";
import Tooltip from "../../Tooltip/Tooltip";

import theme from "../../../theme/theme";
import "../../../utils/style.css";

const SideBarMenuItem = ({ item, isActive, handleMenuClick, expand, logEventProps, headerRight, ...props }) => {
  const [hover, setHover] = useState(false);
  const active = hover || isActive;

  const getJutifySetting = () => {
    if (headerRight) {
      return "space-between";
    }
    if (expand) {
      return "left";
    }
    return "center";
  };

  return (
    <LogEvent logEventProps={item.logEventProps} actionProps={{ onClick: { action: "click" } }} elementType="menu item">
      <Flex bg={active ? theme.colors.lightShade : "transparent"} borderRadius={8} py={expand ? "10px" : 0} px={expand ? 2 : 0} width={expand ? "100%" : "44px"} height="44px" m="auto" cursor={item.onClick ? "pointer" : "default"} alignItems="center" justifyContent={getJutifySetting()} onMouseEnter={() => (item.onClick ? setHover(true) : null)} onMouseLeave={() => (item.onClick ? setHover(false) : null)} onClick={() => handleMenuClick(item)} {...props}>
        {item.logo && (
          <Flex alignItems="center" justifyContent={getJutifySetting()} width="100%">
            <Box as="img" src={item.logo(expand)} />
            {headerRight}
          </Flex>
        )}
        {item.icon && (
          <Flex data-testid={item.testId} justifyContent="center" alignItems="center" width={24} height={24}>
            <FAIcon
              icon={item.icon}
              style={{
                fontSize: 20,
                color: active ? theme.colors.primary : theme.colors.fullShade,
                ...item.iconProps,
              }}
            />
          </Flex>
        )}
        {expand && item.label && (
          <Body ml="14px" medium {...item.labelProps}>
            {item.label}
          </Body>
        )}
      </Flex>
    </LogEvent>
  );
};

const SideBarMenu = (props) => {
  const { expand, item } = props;

  return (
    <Box mb={1}>
      {(expand || !item.label) && <SideBarMenuItem {...props} />}
      {!expand && item.label && (
        <Tooltip
          tooltip={
            <Body small color="white" width="max-content">
              {item.label}
            </Body>
          }
          style={{ textAlign: "center" }}
          toolTipElementProps={{
            ...item.toolTipElementProps,
          }}
          toolTipOptions={{
            strategy: "fixed",
            placement: "right",
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 4],
                },
              },
            ],
          }}
        >
          <SideBarMenuItem {...props} />
        </Tooltip>
      )}
    </Box>
  );
};

const SideBar = ({ headers, footers, menus, additionalMenus, activeTab, sidebarRef, defaultExpand, headerRight, showExpandButton = true, footerProps, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobileHeight = useMediaQuery({ query: "(max-height: 700px)" });

  const isExpand = () => {
    if (!_.isUndefined(defaultExpand)) {
      return defaultExpand;
    }

    if (isMobile || isTablet) {
      return false;
    }
    return true;
  };

  const [expand, setExpand] = useState(isExpand());

  const handleMenuClick = (item) => {
    if (item.onClick) {
      item.onClick(item);
    }
  };

  const handleExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    if (sidebarRef) {
      sidebarRef({ expand, setExpand });
    }
  }, [expand]);

  useEffect(() => {
    setExpand(isExpand());
  }, [isMobile, isTablet]);

  return (
    <Box position="fixed" width={expand ? 280 : 80} zIndex="5" height="100%" bg="lightestShade" overflow={isMobileHeight ? "scroll" : "hidden"} className={isMobileHeight ? "scrollbar-invisible" : null} {...props}>
      <Box mt={2} px={expand ? 3 : 0}>
        <Box mb={7}>
          {_.map(headers, (header, index) => (
            <SideBarMenu key={index} item={header} expand={expand} handleMenuClick={handleMenuClick} headerRight={_.isEqual(index, 0) ? headerRight : null} />
          ))}
        </Box>
        {_.map(menus, (menu, index) => (
          <SideBarMenu key={index} item={menu} isActive={_.isEqual(menu.id, activeTab)} expand={expand} handleMenuClick={handleMenuClick} />
        ))}
        <Box mt={isMobile ? 3 : 8}>
          {_.map(additionalMenus, (item, index) => (
            <SideBarMenu key={index} item={item} isActive={_.isEqual(item.id, activeTab)} expand={expand} handleMenuClick={handleMenuClick} />
          ))}
        </Box>
      </Box>
      <Box bottom={0} zIndex="5" width={expand ? 280 : 80} position={isMobileHeight ? "unset" : "fixed"} marginTop={isMobileHeight ? "100px" : 0} {...footerProps}>
        <Box pl={expand ? 3 : 1} pr={expand ? 3 : 1}>
          {_.map(footers, (footer, index) => (
            <SideBarMenu key={index} item={footer} isActive={_.isEqual(footer.id, activeTab)} expand={expand} handleMenuClick={handleMenuClick} />
          ))}
        </Box>
        {showExpandButton && (
          <Box borderTopWidth={1} borderTopStyle="solid" borderTopColor="mediumShade">
            <SideBarMenuItem
              expand={expand}
              width="100%"
              minHeight={60}
              alignItems="center"
              justifyContent={expand ? "right" : "center"}
              handleMenuClick={handleMenuClick}
              item={{
                icon: expand ? faAngleDoubleLeft : faAngleDoubleRight,
                iconProps: {
                  color: "lightestShade",
                },
                onClick: handleExpand,
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SideBar;
