import { GraphQLClient } from "graphql-request";
import { get, isEqual } from "lodash";

const defaultEndpoint = "https://hippocrates.staging.botmd.io/gql/";

/*  Shared API client for managing api responses, error, and any shared API behavior in single place */
export default class ApiClient {
  constructor(endpoint, headers) {
    this.client = new GraphQLClient(endpoint || defaultEndpoint, { headers });
  }

  async request(graphDocument, variables, headers, opts = {}) {
    const { queryKey, showStaticData } = opts;

    try {
      if (showStaticData) {
        const LOCAL_CACHE = JSON.parse(window.localStorage.getItem("REACT_QUERY_OFFLINE_CACHE") || "{}");
        const existing = get(LOCAL_CACHE, "clientState.queries", []).find((cachedQuery) => isEqual(JSON.stringify(cachedQuery.queryKey), JSON.stringify(queryKey)));

        if (existing) {
          const { data } = existing.state;
          if (data.pages) {
            const { pages } = data;
            return Promise.resolve(pages[0]);
          }
          return Promise.resolve(data);
        }
      }
      const res = this.client.request(graphDocument, variables, headers);

      return res;
    } catch (e) {
      /* handle any expected errors, else throw */
      return null;
    }
  }
}
