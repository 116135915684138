/* eslint global-require:0 */
/* eslint import/no-dynamic-require: 0 */
import React from "react";
import Box from "../Box/Box";

const getIcon = (name) => require(`../../assets/icons/${name}.svg`);

const Icon = ({ name, ...props }) => <Box as="img" src={getIcon(name)} {...props} />;

export default Icon;
