import React, { useState, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";
import Box from "../Box/Box";
import "./Toggle.css";

// Reference from: https://w3collective.com/react-toggle-switch-component/
const Toggle = ({ switchRef, enabled = false, onChange, ...props }) => {
  const [isToggled, setIsToggled] = useState(enabled);
  const propUid = uuidv4();

  const handleChange = () => {
    const newState = !isToggled;
    setIsToggled(newState);

    if (onChange) {
      onChange(newState);
    }
  };

  useEffect(() => {
    if (switchRef) {
      switchRef({ isToggled, setIsToggled });
    }
  }, [isToggled]);

  return (
    <Box as="form" {...props}>
      <label className="toggle-switch" htmlFor={propUid}>
        <input id={propUid} type="checkbox" checked={isToggled} onChange={handleChange} />
        <span className="switch" />
      </label>
    </Box>
  );
};

export default Toggle;
