import React, { createContext, useContext } from "react";
import { initalizeAmplitude, setAmpUserProps } from "../utils/LoggingUtils";

const Context = createContext("");
export const LogContext = Context;

const LogContextProvider = (props) => {
  const { children, logImpression, ...directProps } = props;

  const initAmplitude = async (key) => {
    initalizeAmplitude(key);
  };

  const setAmplitudeUserProps = async (userProps) => {
    setAmpUserProps(userProps);
  };

  return (
    <LogContext.Consumer>
      {(consumedProps) => {
        const combinedProps = { ...consumedProps, ...directProps };
        return (
          <Context.Provider value={{ initAmplitude, setAmplitudeUserProps, combinedProps }}>
            <div style={{ display: "contents" }}>{children}</div>
          </Context.Provider>
        );
      }}
    </LogContext.Consumer>
  );
};

const useLogContext = () => {
  const context = useContext(LogContext);
  if (context === undefined) {
    throw new Error(`useLogContext hook must be used within a LogContextProvider`);
  }
  return context;
};

export { LogContextProvider, useLogContext };
