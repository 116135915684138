import React, { useState } from "react";
import { format, parse } from "date-fns";
import { find, map, isEqual, range } from "lodash";

import Box from "../../Box/Box";
import Flex from "../../Flex/Flex";
import Paper from "../../Paper/Paper";
import { Select } from "../../Select/Select";
import { H5 } from "../../Text/Text";

const Dropdown = ({ label, options, onChange, ...props }) => (
  <Box width="110px">
    <H5>{label}</H5>
    <Box ml={1}>
      <Select onChange={onChange} options={options} maxMenuHeight={180} menuPortalTarget={null} {...props} />
    </Box>
  </Box>
);

const DigitalTime = ({ defaultDate, is24Hr = false, onChange, ...props }) => {
  const hours = map(is24Hr ? range(0, 24) : range(1, 13), (hour) => ({
    label: hour < 10 ? `0${hour}` : hour,
    value: hour,
  }));

  const minutes = map(range(60), (minute) => ({
    label: minute < 10 ? `0${minute}` : minute,
    value: minute,
  }));

  const ampm = [
    {
      label: "AM",
      value: "AM",
    },
    {
      label: "PM",
      value: "PM",
    },
  ];

  const [selectedHours, setSelectedHours] = useState(find(hours, (hour) => isEqual(hour.value, parseInt(format(defaultDate, is24Hr ? "H" : "h"), 10))));

  const [selectedMinutes, setSelectedMinutes] = useState(find(minutes, (minute) => isEqual(minute.value, parseInt(format(defaultDate, "m"), 10))));

  const [selectedAmPm, setSelectedAmPm] = useState(find(ampm, (label) => isEqual(label.value, format(defaultDate, "a"))));

  const handleChange = (type, newValue) => {
    let newDate = "";

    switch (type) {
      case "hour":
        newDate = is24Hr ? `${newValue.value} ${selectedMinutes.value}` : `${newValue.value} ${selectedMinutes.value} ${selectedAmPm.value}`;
        setSelectedHours(newValue);
        break;

      case "minute":
        newDate = is24Hr ? `${selectedHours.value} ${newValue.value}` : `${selectedHours.value} ${newValue.value} ${selectedAmPm.value}`;

        setSelectedMinutes(newValue);
        break;

      case "ampm":
        newDate = `${selectedHours.value} ${selectedMinutes.value} ${newValue.value}`;
        setSelectedAmPm(newValue);
        break;

      default:
        newDate = is24Hr ? `${selectedHours.value} ${selectedMinutes.value}` : `${selectedHours.value} ${selectedMinutes.value} ${selectedAmPm.value}`;
        break;
    }

    if (onChange) {
      onChange(parse(newDate, is24Hr ? "H m" : "h m a", defaultDate));
    }
  };

  return (
    <Paper elevation={1} width="320px" {...props}>
      <Flex p={2} justifyContent="center" alignItems="center">
        <Dropdown label="Hour" options={hours} defaultValue={selectedHours} onChange={(value) => handleChange("hour", value)} />

        <Dropdown label="Minute" defaultValue={selectedMinutes} options={minutes} onChange={(value) => handleChange("minute", value)} />

        {!is24Hr && <Dropdown label="AM/PM" defaultValue={selectedAmPm} options={ampm} onChange={(value) => handleChange("ampm", value)} />}
      </Flex>
    </Paper>
  );
};

export default DigitalTime;
