import React, { useState } from "react";
import { format, isWithinInterval, isAfter, isBefore, startOfMonth, endOfMonth, startOfYear, endOfYear } from "date-fns";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import Box from "../../Box/Box";
import Flex from "../../Flex/Flex";
import Paper from "../../Paper/Paper";
import InputField from "../../InputField/InputField";
import FAIcon from "../../Icon/FAIcon";
import { PrimaryButton } from "../../Button/PrimaryButton";
import { SecondaryOutlinedButton } from "../../Button/SecondaryButton";

import Calendar from "./Calendar";

const MultiCalendar = ({ defaultFromDate, defaultToDate, onChange, onCancel, allDays, allMonths, ...props }) => {
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);

  const handleApplyClick = () => {
    if (onChange) {
      onChange(fromDate, toDate);
    }
  };

  const handleNewFromDate = (date) => {
    if (isBefore(date, toDate)) {
      setFromDate(date);
    }
  };

  const handleNewToDate = (date) => {
    if (isAfter(date, fromDate)) {
      setToDate(date);
    }
  };

  const checkWithin = (date) => {
    if (date) {
      return isWithinInterval(date, { start: fromDate, end: toDate });
    }
    return false;
  };

  const checkValid = (newDate, oldDate, method) => {
    if (newDate) {
      return method(newDate, oldDate);
    }
    return false;
  };

  return (
    <Paper elevation={1} width="640px" {...props}>
      <Box>
        <Box borderBottomStyle="solid" borderBottomWidth={1} borderBottomColor="#EEEDF0">
          <Flex justifyContent="space-between">
            <Box borderRightStyle="solid" borderRightWidth={1} borderRightColor="#EEEDF0">
              <Calendar defaultDate={fromDate} onChange={handleNewFromDate} checkWithin={checkWithin} checkValidDay={(date) => checkValid(date, toDate, isAfter)} checkValidMonth={(date) => checkValid(startOfMonth(date), toDate, isAfter)} checkValidYear={(date) => checkValid(startOfYear(date), toDate, isAfter)} elevation={0} allDays={allDays} allMonths={allMonths} />
            </Box>
            <Calendar defaultDate={toDate} onChange={handleNewToDate} checkWithin={checkWithin} checkValidDay={(date) => checkValid(date, fromDate, isBefore)} checkValidMonth={(date) => checkValid(endOfMonth(date), fromDate, isBefore)} checkValidYear={(date) => checkValid(endOfYear(date), fromDate, isBefore)} elevation={0} allDays={allDays} allMonths={allMonths} />
          </Flex>
        </Box>
        <Flex p={2} justifyContent="space-between">
          <Flex width="50%" alignItems="center">
            <InputField p={1} value={format(fromDate, "dd-MM-yyyy")} disabled />
            <FAIcon icon={faArrowRight} color="fullShade" fontSize={2} style={{ margin: 8 }} />
            <InputField p={1} value={format(toDate, "dd-MM-yyyy")} disabled />
          </Flex>
          <Flex alignItems="center" justifyContent="right">
            <SecondaryOutlinedButton mr={2} onClick={onCancel}>
              Cancel
            </SecondaryOutlinedButton>
            <PrimaryButton onClick={handleApplyClick}>Set Date Range</PrimaryButton>
          </Flex>
        </Flex>
      </Box>
    </Paper>
  );
};

export default MultiCalendar;
