import React from "react";
import PhoneInput from "@fivehealth/react-phone-input-2";
import { createGlobalStyle } from "styled-components";
import "@fivehealth/react-phone-input-2/lib/style.css";

const GlobalStyle = createGlobalStyle`
.bot-container {
  font-family: "Inter", sans-serif;
}

.bot-input {
  border: 1px solid rgb(213, 217, 222) !important;
  border-radius: 8px !important;
  height: auto !important;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 80px !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: rgb(17, 24, 36);
  font-family: inherit;
}

.bot-button {
  border: 1px solid rgb(213, 217, 222) !important;
  border-radius: 8px 0 0 8px !important;

  background-color: transparent !important;
}

.bot-button .selected-flag {
  width: 68px !important;
  padding-left: 16px !important;
}

.bot-button .selected-flag:hover,
.bot-button .selected-flag:focus {
  background: transparent;
}

.bot-button .flag {
  transform: scale(1.4);
}

.bot-button .arrow {
  left: 26px !important;
  margin-top: -3px !important;
}

.bot-dropdown {
  font-family: "Inter", sans-serif;
  border-radius: 8px !important;
  box-shadow: 0px 6px 12px 0px #98a2b326, 0px 4px 4px 0px #98a2b326,
    0px 2px 2px 0px #98a2b326;
}

.bot-dropdown .country-name {
  margin-left: 16px;
  margin-right: 12px !important;
}

.bot-dropdown .country {
  padding: 12px !important;
  padding-left: 16px !important;
}

.bot-dropdown .country:hover {
  background-color: #256bf614 !important;
}

.bot-dropdown .country.highlight {
  background-color: #256bf614 !important;
}

.bot-input.form-control {
  width: auto;
}

.bot-button.flag-dropdown {
  height: 45px;
}

`;

const PhoneInputField = (props) => (
  <>
    <GlobalStyle />
    <PhoneInput inputClass="bot-input" buttonClass="bot-button" containerClass="bot-container" dropdownClass="bot-dropdown" {...props} />
  </>
);

export default PhoneInputField;
